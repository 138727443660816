@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

* {
  font-family: "Poppins", sans-serif;
    margin: 0;  
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
}

.Page{
  width: 100%;
  height: calc(100vh - 80px);
  background-color: lightblue;
}
.map{
  width: 100%;
  height: calc(100vh - 80px);
  z-index: -1;
}
.map-container{
  width: 100%;
  height: calc(100vh - 80px);
  z-index: 1;
}