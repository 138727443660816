

.region-selector{
    position: absolute;
    z-index: 100;
    width: fit-content;
    height: 40px;
    align-self: center;
    margin-left: 50%;
    /* top: 100px; */
    top: -45px;
    transform: translateX(-50%);
    padding: 8px;
    border-radius: 25px;
    border: none;
    padding-right: 10px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.41);
    transition: .2s;
}

.region-selector.active{
    top: 100px
}
