.button-redo{
    position: absolute;
    z-index: 100;
    right: 2vh;
    top: -40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    text-align: center;
    background-color: #0455BF;
    transition: .2s;
    outline: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.41);
}

.button-redo.active{
    top:100px
}

.button-redo svg{
    position: relative;
    top: 3px;
    font-size: 25px;
    color: white;
}
.button-redo:hover{
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.49);
    background-color: #0468BF;
    transition: .2s;
}
.button-redo:active{
    background-color: #0468BF;
    box-shadow: 0 3px rgba(0, 0, 0, 0.49);
    transform: translateY(2px);

}

