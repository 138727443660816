.sidebar{
    background-color: #0442BF;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

.menu-bar {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
    color: white;
}

.side-menu{
    background-color:#0455BF ;
    width: 200px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: .5s;
    z-index: 10000;
}

.side-menu.active{
    left: 0;
    transition: .5s;
}

.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 60px;
}

.nav-text a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    border-radius: 4px;
}

.nav-text a:hover{
    background-color: #0468BF;
}

.side-menu-itens{
    width: 100%;
}

.sidebar-toggle{
   background-color: #0455BF;
   width: 100%;
   height: 80px;
   display: flex;
   justify-content: start;
   align-items: center;
}
.sidebar-toggle svg{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: white;
}

span{
    margin-left: 16px;
}